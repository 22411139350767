<template>
  <div class="default-layout">
    <header class="px-4 py-[80px] bg-[#F1F1F1]">
      <div class="mx-auto max-w-[620px] text-center">
        <div class="mb-[32px]">
          <template v-if="$route.path === '/'">
            <img width="502" height="160" src="/images/website/logo.svg" alt="Deals Poker" class="mx-auto">
          </template>

          <template v-else>
            <router-link to="/" title="home">
              <img width="502" height="160" src="/images/website/logo.svg" alt="Deals Poker" class="mx-auto">
            </router-link>
          </template>
        </div>

        <h2 class="mb-[53px]">Хорошие сделки - для хороших игроков</h2>

        <div v-if="$route.path !== '/'" class="flex flex-wrap justify-center gap-[60px] gap-y-4">
          <router-link to="/" class="c-btn c-btn-black">
            ← вернуться к выбору румов
          </router-link>

          <template v-if="backLink">
            <router-link
              v-if="!linkExternal"
              :to="backLink.link"
              class="c-btn c-btn-red"
            >
              {{backLink.title}}
            </router-link>

            <a v-else :href="backLink.link" target="_blank" class="c-btn c-btn-red">
              {{backLink.title}}
            </a>
          </template>
        </div>
      </div>
    </header>

    <router-view @on-page-content-loaded="handlePageContentLoaded"/>

    <div class="py-4"></div>
    <div class="hidden ml-4 ml-3 ml-2 mx-4 ml-5"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      backLink: null,
      linkExternal: false,
    }
  },
  methods: {
    handlePageContentLoaded(link) {
      this.backLink = link;

      if (link?.link) {
        this.linkExternal = link.link.includes('http');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.default-layout-message-text {
  margin-bottom: 0;
}
</style>
