import {helper as $h} from '@/utils/helper';

const state = () => {
  return {
      menu: [
          {
              icon: 'HomeIcon',   // Иконка для сабменю
              pageName: 'admin',   // Берет из роутера имя и подставляет его PATH
              title: 'Главная',   // Название отображения
          },
          {
              icon: 'UsersIcon',
              pageName: 'users',
              title: 'Пользователи',
          },
          {
              icon: 'ListIcon',
              pageName: 'pages',
              title: 'Страницы',
          },
      ],
    formattedMenu: null,
  }
}

// getters
const getters = {
  menu: state => state.menu,
  formattedMenu: state => state.formattedMenu
}

// actions
const actions = {
  formatMenu({commit, getters}, {userRules, router}) {
    commit('setFormattedMenu', $h.allowedMenu(getters.menu, userRules, router))
  }
}

// mutations
const mutations = {
  setMenu(state, payload) {
    state.menu = payload
  },
  clearMenuState(state) {
    state.menu = null
  },
  setFormattedMenu(state, payload) {
    state.formattedMenu = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
